#silent-note{
  strong{
    color: #59d86c;
  }
  
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  text-align: center;
  max-width: 750px;
  background: #2b2b2b;
  padding: 20px;
  color: white;
  display: none;
}

@media all and (max-width: 298px){

  .responsiveFilters{
    padding: 5px 0px !important;

    .translations{
      border-right: none !important;
      border-bottom: 1px solid #636363 !important;
      padding-bottom: 15px !important;
    }

  }



}

@media all and (max-width: 338px){

  .filters{
    padding: 5px 0px !important;
  }

}

@media all and (max-width: 500px){
  #surah-arabic-svg{
    img{
      max-width: 250px !important;
    }
  }
}

@media all and (max-width: 535px){
  .surah-navigator {

    .previous{
      margin-bottom: 25px !important;
    }
    .next{
      margin-top: 25px !important;
    }

    a{
      display: block !important;
      margin: 0 auto !important;
    }
  }
}

@media all and (max-width: 710px){

  .desktopFilters{
    display: none !important;
  }

  .responsiveFilters{
    display: block !important;
  }

  .filters{

    .dropbtn{

      &:hover{
        color: #fff !important;
      }

    }

  }

  .moreSettings{

    &:hover{
      svg{
        fill: #fff !important;
      }

      .label{
        color: #fff !important;
      }
    }
  }
}

.arabic{
  font-family: 'Scheherazade', serif;
}


.responsiveFilters{
  display: none;
  .dropdown-content{
    left: 0 !important;
    right: 0 !important;
    top: 33px !important;
  }
  .showMore{
    .label{
      color: #0DFF92 !important;
    }

    svg{
      fill: #0DFF92 !important;
    }
  }
  .moreSettings{

    position: relative;
    cursor: pointer;

    &:hover{
      svg{
        fill: #0DFF92;
      }

      .label{
        color: #0DFF92;
      }
    }
    svg{
      width: 16px;
      display: inline-block;
      height: auto;
      fill: #fafafa;
      // position: absolute;
      // left: -5px;
      // top: 8px;
      display: inline-block;
      position: relative;
      top: 2px;
      left: -5px;
    }

    .label{
      display: inline-block;
    }
  }

}

#hiddenFilters{
  display: none;

  .options{
    margin-top: 3px !important;
  }

  .option{
    border-right: none !important;
    margin: 7px 0 !important;
  }

  .dropdown-content{
    left: 0 !important;
    right: 0 !important;
    top: 33px !important;
  }
}
.toolbar{

  position: absolute;
  top: 10px;
  left: 10px;

  .verseChapter{
    display: inline-block;
    background: #e4e4e4;
    padding: 5px 10px;
    border-radius: 3px;
    }

    #playPauseBtn {
      position: absolute;
      top: 0;
      left: 70px;
      background: #e4e4e4;
      cursor: pointer;

      .showPause{
        width: 84px !important;
      }
      &:hover{
        background: #48a48d;

        .status-text{
          color: white !important;
        }

        .status-icon svg{
          fill: white;
        }
      }

      ul{
        padding: 0;
        margin: 0;
        list-style: none;
        padding: 5px 10px;
        width: 70px;

        li{
          display: inline-block;
          margin: 0;
          padding: 0;
        }
      }

      svg{
        width: 14px;
        display: inline-block;
        height: auto;
        fill: #505050;
    }

    .status-icon{
      display: inline;
      padding: 0;
      margin: 0;
      position: relative;
      top: 1px;
    }

    .status-text{
      font-size: 15px;
      font-weight: bold;
      color: #505050;
      display: inline;
    }
  }
}

.chapterBg{
  background: #4CAF7E !important;
}

.jumbotron{
  background: #4CAF7E;
  background: -webkit-linear-gradient(to right, #11998e, #48e684);
  background: linear-gradient(to right, #11998e, #48e684);
  text-align: center;
  padding: 50px 10px;

  h1{
    color: white;
    font-size: 30px;
    margin: 0 auto;
    margin-top: 30px;
  }
}


.jumbotron{

  img{
    max-width: 300px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
  }

  .surah-english .name{
    margin: 0;
    color: white;
    margin-top: 30px;
    font-weight: 500;
    font-size: 28px;
  }

  .surah-english .transliteration{
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    color: #22613f;
  }

}

.section h1{
  text-align: center;
  color: #404040;
  font-size: 35px;
  border-bottom: 3px solid #e2e2e2;
  padding-bottom: 11px;
}

.content{
  max-width: 900px;
  margin: 0 auto;
  padding: 30px 10px;
}

.section .chapters ul{
  list-style: none;
  margin: 0;
  padding: 0;

  a{
    text-decoration: none !important;
    color: #404040 !important;
    &:hover{
      li{
        background: #ecfbec;
        border-radius: 5px;
      }
    }
  }
  li{
    padding: 20px 0;
    color: #404040 !important;
    position: relative;
    text-decoration: none !important;
  }


  // .left2{
  //   left: -9px;
  // }
  //
  // .left3{
  //   left: -18px;
  // }

  .left1{
    left: 10px;
  }

  .left2{
    left: 1px;
  }

  .left3{
    left: -9px;
  }

  .chapter-name{
    padding-left: 60px;

    .english{
      font-size: 15px;
      margin-top: 10px;
    }
  }

  .arabic_name{
    font-size: 30px;
    color: #48a48d;
  }

  .english_name{
    font-weight: bold;
  }

  .english_translation{
    margin-top: 3px;
  }

  .number{
    // background: #5bc688;
    // color: white;
    // border-radius: 50%;

    // color: #a7a7a7;
    color: #888;
    padding: 5px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

}



.surah .pageview{

  text-align: center !important;
  direction: rtl !important;

  .toolbar{
    display: none !important;
  }

  .bismillah img{
    width: 350px !important;
  }
  .verse{
    padding: 20px 0 !important;
    border: none !important;
    display: inline-block !important;
  }

  .arabic-style{
    font-size: 45px !important;
    .verse-icon{
      width: 45px !important;
    }
  }

}


.surah .verses{

  .greenHighlight{
    // background: #e4f7e4;
    background: #ebfbeb;
    // border: 2px solid #d5e2d5;
  }

  .bismillah{
    text-align: center !important;
    font-size: 60px !important;
    border-bottom: 1px solid #e2e2e2 !important;
    margin-bottom: 20px;

    img{
      max-width: 400px;
      width: 100%;
      padding: 0 10px;
      padding-bottom: 20px;
      margin-top: 30px;
    }
  }
  .verse{
    padding: 50px 10px;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
  }
  .arabic-style{
    font-size: 50px;
    // font-weight: bold;
    color: #2b2b2b;
    text-align: right;
    direction: rtl;

    .verse-end{
      position: relative;
      display: inline-block;
    }

    .number{
      font-size: 25px;
      font-weight: bold;
      position: absolute;
      top: 2px;
      right: 17px;
    }

    .double{
      right: 12px !important;
    }

    .triple{
      right: 7px !important;
    }

  }

  .translation{
    margin-top: 30px;
    // font-weight: bold;
    color: #868686;
    // color: #48a48d;
    font-size: 19px;

    .author span{
      text-transform: uppercase;
    }

    .text{
      color: #48a48d;
      font-weight: bold;
      margin-top: 10px;
    }
  }

}


/* The dropdown container */

#sticky {
    // display: none;
    // background-color: #546bcb;
    // height: 70px;
}

.rtlTranslation{
  direction: rtl;
  text-align: left;
}

.urduTranslation{
  // direction: rtl;
  font-family: Nafees !important;
  // text-align: right;
  // line-height: 30px;
}
.fixedFilters {
    // display: block;
    position: fixed;
    top: 0;
    width: 100%;
}

.filters{
  padding: 5px 10px;
  font-size: 14px;
  background: #2b2b2b;
  z-index: 2;

  .homeOption{
    a{
      color: white;
      text-decoration: none !important;

      &:hover{
        text-decoration: none !important;
        color: #0DFF92 !important;
      }
    }
  }
  .iconOption{
    position: relative;
    cursor: pointer;

    &:hover{
      svg{
        fill: #0DFF92 !important;
      }

      .label{
        color: #0DFF92 !important;
      }
    }
    svg{
      width: 16px;
      display: inline-block;
      height: auto;
      fill: #fafafa;
      // position: absolute;
      // left: -5px;
      // top: 8px;
      display: inline-block;
      position: relative;
      top: 2px;
      left: -5px;
    }

    .label{
      display: inline-block;
    }
  }
  .simpleDropDown{

      .versePicker{
        color: white;
        text-decoration: none !important;
        font-size: 16px;
        border-bottom: 1px solid #333;
        padding: 10px 5px 10px 0;
        display: block;
        cursor: pointer;
        transition: 0.25s linear;
        -webkit-transition: 0.25s linear;
        &:hover{
            color: #0DFF92;
        }
      }

      ul .selected{
        color: #0DFF92 !important;
      }

      ul a{
        color: white;
        text-decoration: none !important;
        font-size: 16px;
        border-bottom: 1px solid #333;
        padding: 10px 5px 10px 0;
        display: block;
        transition: 0.25s linear;
        -webkit-transition: 0.25s linear;
        &:hover{
            color: #0DFF92;
        }
      }
  }


  .options{
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .inlineAdjuster{
    padding-right: 90px !important;
    position: relative !important;

    .adjustmentSwitch{

      -webkit-touch-callout: none;
      -webkit-user-select: none; /* Chrome/Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+ */
      /* Rules below not implemented in browsers yet */
      -o-user-select: none;
      user-select: none;



      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-block;
      color: #333;
      font-weight: bold;
      background: #fafafa;
      border-radius: 4px;
      position: absolute !important;
      top: 1px !important;
      right: 12px !important;
      overflow: hidden;
      li{
        padding: 0;
        margin: 0;
        display: inline-block;
        cursor: pointer;

        &:hover{
          background: #5ab588 !important;
          color: white !important;
        }
      }

      .minus{
        padding: 2px 12px;
        font-size: 18px;
        display: inline-block;
        position: relative;
        margin-right: -4px;
      }
      .plus{
        position: relative;
        padding: 2px 12px;
        font-size: 18px;
        display: inline-block;
        border-left: 1px solid #d2d2d2;
      }
    }
  }
  .inlineToggle {
    padding-right: 80px !important;
    position: relative !important;

    .switch{
      margin: 0 !important;
      padding: 0 !important;
      position: absolute !important;
      top: 3px !important;
      right: 15px !important;
    }
  }

  .option{
    display: inline-block;
    border-right: 1px solid #636363;
    padding: 6px 20px;
    margin: 5px 0;
    color: white;
  }


  .caret{
    font-size: 9px;
    position: relative;
    margin-left: 5px;
    top: -1px;
  }

  .dropdown {
    position: relative;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .dropbtn{
    border: none;
    outline: none;
    // color: #565656;
    color: white;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    // border: 1px solid #cacaca;
    // padding: 6px 20px;
    // border-radius: 3px;

    &:hover{
      color: #0DFF92;
    }
  }

  .dropdown:hover .dropbtn {
    // background-color: #2b2b2b;
    // color: white !important;
  }

  .menuShown{
    // background-color: #2b2b2b;
    // color: white !important;
    color: #0DFF92 !important;
  }

  .allVerses .dropdown-content{
    width: 123px !important;
    text-align: center !important;
    padding-left: 10px !important;
  }

  .allChapters .dropdown-content{
    width: 220px !important;
  }

  .dropdown-content {
    text-align: left;
    opacity: 0;
    z-index: -10;
    display: none;
    -webkit-transition: opacity .15s ease-in-out;
    -moz-transition: opacity .15s ease-in-out;
    -ms-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;

    padding: 20px 10px 10px 20px;
    right: -22px;
    left: auto;
    // display: none;
    position: absolute;
    color: white;
    background-color: #2b2b2b;
    width: 250px;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    // border-radius: 3px;
    // margin-top: 10px;
    margin-top: 5px;

    /***** ADD A CARET ARROW TO TOP RIGHT OF DROP DOWN MENU *****/
    // &:before{
    //   position: absolute;
    //   right: 7px;
    //   margin-left: 0;
    //   top: -9px;
    //   width: 0;
    //   height: 0;
    //   content: '';
    //   border-color: #000;
    //   border-left: 10px solid transparent;
    //   border-right: 10px solid transparent;
    //   border-bottom: 10px solid #323232;
    // }

    .item{
      float: none;
      color: white;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
      font-size: 14px;
    }

    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        padding: 0;
        margin: 0;
      }
    }
  }
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    // display: block;
  }


.container{
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 250px;
  overflow-y: scroll;

  .toggle{
      margin-bottom: 25px;
  }

  .section{
    font-size: 16px;
    color: white;
  }
}


}

.surah-navigator {
  text-align: center;
  margin-top: 30px;
  span{
    margin: 0 20px;
  }
  a{
    text-decoration: none !important;
    padding: 10px;
    background: #48a48d;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    max-width: 150px;
    width: 100%;
    display: inline-block;

    span{
      margin: 0 !important;
    }
    &:hover{
      background: #198066 !important;
      text-decoration: none !important;
      color: white !important;
    }

    &:focus{
      background: #198066 !important;
      text-decoration: none !important;
      color: white !important;
    }

  }
}
.fullOpacity{
  opacity: 100 !important;
  z-index: 100 !important;
  display: block !important;
}
.available-translations{


    list-style: none;
    margin: 0;
    padding: 0;
  	overflow: auto;

   li{
    color: #AAAAAA;
    display: block;
    position: relative;
  	border-bottom: 1px solid #333;
  }

   li input[type=radio]{
    position: absolute;
    visibility: hidden;
  }

   li label{
    display: block;
    position: relative;
    // font-weight: 300;
    font-size: 16px;
    padding: 10px;
    padding-left: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }

   li:hover label{
  	color: #FFFFFF;
  }

   li .check{
    display: block;
    position: absolute;
    border: 3px solid #AAAAAA;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 13px;
    // left: 20px;
  	z-index: 5;
  	transition: border .25s linear;
  	-webkit-transition: border .25s linear;
  }

   li:hover .check {
    border: 3px solid #FFFFFF;
  }

   li .check::before {
    display: none;
    position: absolute;
  	content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 5px;
  	left: 5px;
    margin: auto;
  	transition: background 0.25s linear;
  	-webkit-transition: background 0.25s linear;
  }

  .showChecked .check {
    border: 3px solid #0DFF92 !important;
    background: #0DFF92 !important;

  }

  .showChecked .check::before{
    background: #0DFF92 !important;
  }

  .showChecked label{
    color: #0DFF92 !important;
  }
  //
  // input[type=radio]:checked ~ .check {
  //   border: 3px solid #0DFF92 !important;
  //   background: #0DFF92 !important;
  //
  // }
  //
  // input[type=radio]:checked ~ .check::before{
  //   background: #0DFF92 !important;
  // }
  //
  // input[type=radio]:checked ~ label{
  //   color: #0DFF92 !important;
  // }
}


.switch{

  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  top: 7px;
  margin-left: 10px;


input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s !important;
  transition: .4s !important;
}

input:checked + .slider {
  background-color: #0DFF92;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0DFF92;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
}
