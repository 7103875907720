#silent-note {
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  text-align: center;
  max-width: 750px;
  background: #2b2b2b;
  padding: 20px;
  color: white;
  display: none;
}

#silent-note strong {
  color: #59d86c;
}

@media all and (max-width: 298px) {
  .responsiveFilters {
    padding: 5px 0px !important;
  }
  .responsiveFilters .translations {
    border-right: none !important;
    border-bottom: 1px solid #636363 !important;
    padding-bottom: 15px !important;
  }
}

@media all and (max-width: 338px) {
  .filters {
    padding: 5px 0px !important;
  }
}

@media all and (max-width: 500px) {
  #surah-arabic-svg img {
    max-width: 250px !important;
  }
}

@media all and (max-width: 535px) {
  .surah-navigator .previous {
    margin-bottom: 25px !important;
  }
  .surah-navigator .next {
    margin-top: 25px !important;
  }
  .surah-navigator a {
    display: block !important;
    margin: 0 auto !important;
  }
}

@media all and (max-width: 710px) {
  .desktopFilters {
    display: none !important;
  }
  .responsiveFilters {
    display: block !important;
  }
  .filters .dropbtn:hover {
    color: #fff !important;
  }
  .moreSettings:hover svg {
    fill: #fff !important;
  }
  .moreSettings:hover .label {
    color: #fff !important;
  }
}

.arabic {
  font-family: 'Scheherazade', serif;
}

.responsiveFilters {
  display: none;
}

.responsiveFilters .dropdown-content {
  left: 0 !important;
  right: 0 !important;
  top: 33px !important;
}

.responsiveFilters .showMore .label {
  color: #0DFF92 !important;
}

.responsiveFilters .showMore svg {
  fill: #0DFF92 !important;
}

.responsiveFilters .moreSettings {
  position: relative;
  cursor: pointer;
}

.responsiveFilters .moreSettings:hover svg {
  fill: #0DFF92;
}

.responsiveFilters .moreSettings:hover .label {
  color: #0DFF92;
}

.responsiveFilters .moreSettings svg {
  width: 16px;
  display: inline-block;
  height: auto;
  fill: #fafafa;
  display: inline-block;
  position: relative;
  top: 2px;
  left: -5px;
}

.responsiveFilters .moreSettings .label {
  display: inline-block;
}

#hiddenFilters {
  display: none;
}

#hiddenFilters .options {
  margin-top: 3px !important;
}

#hiddenFilters .option {
  border-right: none !important;
  margin: 7px 0 !important;
}

#hiddenFilters .dropdown-content {
  left: 0 !important;
  right: 0 !important;
  top: 33px !important;
}

.toolbar {
  position: absolute;
  top: 10px;
  left: 10px;
}

.toolbar .verseChapter {
  display: inline-block;
  background: #e4e4e4;
  padding: 5px 10px;
  border-radius: 3px;
}

.toolbar #playPauseBtn {
  position: absolute;
  top: 0;
  left: 70px;
  background: #e4e4e4;
  cursor: pointer;
}

.toolbar #playPauseBtn .showPause {
  width: 84px !important;
}

.toolbar #playPauseBtn:hover {
  background: #48a48d;
}

.toolbar #playPauseBtn:hover .status-text {
  color: white !important;
}

.toolbar #playPauseBtn:hover .status-icon svg {
  fill: white;
}

.toolbar #playPauseBtn ul {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 5px 10px;
  width: 70px;
}

.toolbar #playPauseBtn ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.toolbar #playPauseBtn svg {
  width: 14px;
  display: inline-block;
  height: auto;
  fill: #505050;
}

.toolbar #playPauseBtn .status-icon {
  display: inline;
  padding: 0;
  margin: 0;
  position: relative;
  top: 1px;
}

.toolbar #playPauseBtn .status-text {
  font-size: 15px;
  font-weight: bold;
  color: #505050;
  display: inline;
}

.chapterBg {
  background: #4CAF7E !important;
}

.jumbotron {
  background: #4CAF7E;
  background: -webkit-linear-gradient(to right, #11998e, #48e684);
  background: linear-gradient(to right, #11998e, #48e684);
  text-align: center;
  padding: 50px 10px;
}

.jumbotron h1 {
  color: white;
  font-size: 30px;
  margin: 0 auto;
  margin-top: 30px;
}

.jumbotron img {
  max-width: 300px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}

.jumbotron .surah-english .name {
  margin: 0;
  color: white;
  margin-top: 30px;
  font-weight: 500;
  font-size: 28px;
}

.jumbotron .surah-english .transliteration {
  margin: 0;
  margin-top: 10px;
  font-size: 16px;
  color: #22613f;
}

.section h1 {
  text-align: center;
  color: #404040;
  font-size: 35px;
  border-bottom: 3px solid #e2e2e2;
  padding-bottom: 11px;
}

.content {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px 10px;
}

.section .chapters ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section .chapters ul a {
  text-decoration: none !important;
  color: #404040 !important;
}

.section .chapters ul a:hover li {
  background: #ecfbec;
  border-radius: 5px;
}

.section .chapters ul li {
  padding: 20px 0;
  color: #404040 !important;
  position: relative;
  text-decoration: none !important;
}

.section .chapters ul .left1 {
  left: 10px;
}

.section .chapters ul .left2 {
  left: 1px;
}

.section .chapters ul .left3 {
  left: -9px;
}

.section .chapters ul .chapter-name {
  padding-left: 60px;
}

.section .chapters ul .chapter-name .english {
  font-size: 15px;
  margin-top: 10px;
}

.section .chapters ul .arabic_name {
  font-size: 30px;
  color: #48a48d;
}

.section .chapters ul .english_name {
  font-weight: bold;
}

.section .chapters ul .english_translation {
  margin-top: 3px;
}

.section .chapters ul .number {
  color: #888;
  padding: 5px 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.surah .pageview {
  text-align: center !important;
  direction: rtl !important;
}

.surah .pageview .toolbar {
  display: none !important;
}

.surah .pageview .bismillah img {
  width: 350px !important;
}

.surah .pageview .verse {
  padding: 20px 0 !important;
  border: none !important;
  display: inline-block !important;
}

.surah .pageview .arabic-style {
  font-size: 45px !important;
}

.surah .pageview .arabic-style .verse-icon {
  width: 45px !important;
}

.surah .verses .greenHighlight {
  background: #ebfbeb;
}

.surah .verses .bismillah {
  text-align: center !important;
  font-size: 60px !important;
  border-bottom: 1px solid #e2e2e2 !important;
  margin-bottom: 20px;
}

.surah .verses .bismillah img {
  max-width: 400px;
  width: 100%;
  padding: 0 10px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.surah .verses .verse {
  padding: 50px 10px;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
}

.surah .verses .arabic-style {
  font-size: 50px;
  color: #2b2b2b;
  text-align: right;
  direction: rtl;
}

.surah .verses .arabic-style .verse-end {
  position: relative;
  display: inline-block;
}

.surah .verses .arabic-style .number {
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: 2px;
  right: 17px;
}

.surah .verses .arabic-style .double {
  right: 12px !important;
}

.surah .verses .arabic-style .triple {
  right: 7px !important;
}

.surah .verses .translation {
  margin-top: 30px;
  color: #868686;
  font-size: 19px;
}

.surah .verses .translation .author span {
  text-transform: uppercase;
}

.surah .verses .translation .text {
  color: #48a48d;
  font-weight: bold;
  margin-top: 10px;
}

/* The dropdown container */
.rtlTranslation {
  direction: rtl;
  text-align: left;
}

.urduTranslation {
  font-family: Nafees !important;
}

.fixedFilters {
  position: fixed;
  top: 0;
  width: 100%;
}

.filters {
  padding: 5px 10px;
  font-size: 14px;
  background: #2b2b2b;
  z-index: 2;
  /* Show the dropdown menu on hover */
}

.filters .homeOption a {
  color: white;
  text-decoration: none !important;
}

.filters .homeOption a:hover {
  text-decoration: none !important;
  color: #0DFF92 !important;
}

.filters .iconOption {
  position: relative;
  cursor: pointer;
}

.filters .iconOption:hover svg {
  fill: #0DFF92 !important;
}

.filters .iconOption:hover .label {
  color: #0DFF92 !important;
}

.filters .iconOption svg {
  width: 16px;
  display: inline-block;
  height: auto;
  fill: #fafafa;
  display: inline-block;
  position: relative;
  top: 2px;
  left: -5px;
}

.filters .iconOption .label {
  display: inline-block;
}

.filters .simpleDropDown .versePicker {
  color: white;
  text-decoration: none !important;
  font-size: 16px;
  border-bottom: 1px solid #333;
  padding: 10px 5px 10px 0;
  display: block;
  cursor: pointer;
  transition: 0.25s linear;
  -webkit-transition: 0.25s linear;
}

.filters .simpleDropDown .versePicker:hover {
  color: #0DFF92;
}

.filters .simpleDropDown ul .selected {
  color: #0DFF92 !important;
}

.filters .simpleDropDown ul a {
  color: white;
  text-decoration: none !important;
  font-size: 16px;
  border-bottom: 1px solid #333;
  padding: 10px 5px 10px 0;
  display: block;
  transition: 0.25s linear;
  -webkit-transition: 0.25s linear;
}

.filters .simpleDropDown ul a:hover {
  color: #0DFF92;
}

.filters .options {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.filters .inlineAdjuster {
  padding-right: 90px !important;
  position: relative !important;
}

.filters .inlineAdjuster .adjustmentSwitch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: #333;
  font-weight: bold;
  background: #fafafa;
  border-radius: 4px;
  position: absolute !important;
  top: 1px !important;
  right: 12px !important;
  overflow: hidden;
}

.filters .inlineAdjuster .adjustmentSwitch li {
  padding: 0;
  margin: 0;
  display: inline-block;
  cursor: pointer;
}

.filters .inlineAdjuster .adjustmentSwitch li:hover {
  background: #5ab588 !important;
  color: white !important;
}

.filters .inlineAdjuster .adjustmentSwitch .minus {
  padding: 2px 12px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  margin-right: -4px;
}

.filters .inlineAdjuster .adjustmentSwitch .plus {
  position: relative;
  padding: 2px 12px;
  font-size: 18px;
  display: inline-block;
  border-left: 1px solid #d2d2d2;
}

.filters .inlineToggle {
  padding-right: 80px !important;
  position: relative !important;
}

.filters .inlineToggle .switch {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  top: 3px !important;
  right: 15px !important;
}

.filters .option {
  display: inline-block;
  border-right: 1px solid #636363;
  padding: 6px 20px;
  margin: 5px 0;
  color: white;
}

.filters .caret {
  font-size: 9px;
  position: relative;
  margin-left: 5px;
  top: -1px;
}

.filters .dropdown {
  position: relative;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.filters .dropbtn {
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.filters .dropbtn:hover {
  color: #0DFF92;
}

.filters .menuShown {
  color: #0DFF92 !important;
}

.filters .allVerses .dropdown-content {
  width: 123px !important;
  text-align: center !important;
  padding-left: 10px !important;
}

.filters .allChapters .dropdown-content {
  width: 220px !important;
}

.filters .dropdown-content {
  text-align: left;
  opacity: 0;
  z-index: -10;
  display: none;
  -webkit-transition: opacity .15s ease-in-out;
  -moz-transition: opacity .15s ease-in-out;
  -ms-transition: opacity .15s ease-in-out;
  -o-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
  padding: 20px 10px 10px 20px;
  right: -22px;
  left: auto;
  position: absolute;
  color: white;
  background-color: #2b2b2b;
  width: 250px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  margin-top: 5px;
  /***** ADD A CARET ARROW TO TOP RIGHT OF DROP DOWN MENU *****/
}

.filters .dropdown-content .item {
  float: none;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 14px;
}

.filters .dropdown-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.filters .dropdown-content ul li {
  padding: 0;
  margin: 0;
}

.filters .container {
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 250px;
  overflow-y: scroll;
}

.filters .container .toggle {
  margin-bottom: 25px;
}

.filters .container .section {
  font-size: 16px;
  color: white;
}

.surah-navigator {
  text-align: center;
  margin-top: 30px;
}

.surah-navigator span {
  margin: 0 20px;
}

.surah-navigator a {
  text-decoration: none !important;
  padding: 10px;
  background: #48a48d;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  max-width: 150px;
  width: 100%;
  display: inline-block;
}

.surah-navigator a span {
  margin: 0 !important;
}

.surah-navigator a:hover {
  background: #198066 !important;
  text-decoration: none !important;
  color: white !important;
}

.surah-navigator a:focus {
  background: #198066 !important;
  text-decoration: none !important;
  color: white !important;
}

.fullOpacity {
  opacity: 100 !important;
  z-index: 100 !important;
  display: block !important;
}

.available-translations {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.available-translations li {
  color: #AAAAAA;
  display: block;
  position: relative;
  border-bottom: 1px solid #333;
}

.available-translations li input[type=radio] {
  position: absolute;
  visibility: hidden;
}

.available-translations li label {
  display: block;
  position: relative;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

.available-translations li:hover label {
  color: #FFFFFF;
}

.available-translations li .check {
  display: block;
  position: absolute;
  border: 3px solid #AAAAAA;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 13px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

.available-translations li:hover .check {
  border: 3px solid #FFFFFF;
}

.available-translations li .check::before {
  display: none;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
  left: 5px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.available-translations .showChecked .check {
  border: 3px solid #0DFF92 !important;
  background: #0DFF92 !important;
}

.available-translations .showChecked .check::before {
  background: #0DFF92 !important;
}

.available-translations .showChecked label {
  color: #0DFF92 !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  top: 7px;
  margin-left: 10px;
  /* Rounded sliders */
}

.switch input {
  display: none;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s !important;
  transition: .4s !important;
}

.switch input:checked + .slider {
  background-color: #0DFF92;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #0DFF92;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}
